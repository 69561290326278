import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Main.module.css";
import LiveAnbox from "../LiveAnbox/LiveAnbox";

import { depositlink } from "../../redux/actions/depositAction";
import { loginPost } from "../../redux/actions/loginAction";
import { logoutGet } from "../../redux/actions/logoutAction";
import { signPost } from "../../redux/actions/signAction";
import { allboxes } from "../../redux/actions/allboxesAction";
import { balanceget } from "../../redux/actions/balanceGetAction";
import { loginGoogle } from "../../redux/actions/googleAuthAction";
import Footer from "../../components/Footer/Footer";

import StickyMenuLogInOptions from '../../components/StickyMenuLogInOptions/StickyMenuLogInOptions'
import DepositPopup from '../../components/DepositPopup/DepositPopup';
import WithdrawPopup from '../../components/WithdrawPopup/WithdrawPopup';
import LoginPopup from "../../components/LoginPopup/LoginPopup";
import SignUpPopup from "../../components/SignUpPopup/SignUpPopup";

import StickyMenuTop from "../../components/StickyMenuTop/StickyMenuTop"

const AboutUs = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.example.loading);
  const characters = useSelector((state) => state.example.payload);
  const payloadType = useSelector((state) => state.example.payloadType)
  const [balance, setBalance] = React.useState('0.00')
  const [bonusBoxes, setBonusBoxes] = React.useState([])
  const [loggedIn, setLoggedIn] = React.useState(true);
  const [showDepositPopup, setShowDP] = React.useState(false);
  const [showWithdrawalPopup, setShowWP] = React.useState(false);
  const [showSignUpPopup, setShowSUP] = React.useState(false);
  const [showLoginPopup, setShowLP] = React.useState(false);
  const [isDepositing, setIsDepositing] = React.useState(false);
  const [showSMLIO, setShowSMLIO] = React.useState(true);
  const [isSignError, setIsSignError] = React.useState(false);
  const [isLoginError, setIsLoginError] = React.useState(false);
  const redir = () => {
    window.location.href = '/'
  }



  const toggleSMLIO = () => {
    setShowSMLIO(!showSMLIO)
  }

  const toggleDP = () => {
    setShowDP(!showDepositPopup)
  }
  const toggleWP = () => {
    setShowWP(!showWithdrawalPopup)
  }

  const toggleSUP = () => {
    setShowSUP(!showSignUpPopup)
    setShowLP(false)
    toggleSMLIO()
  }

  const toggleLP = () => {
    setShowLP(!showLoginPopup)
    setShowSUP(false)
    toggleSMLIO()
  }


  const handleLogin = (username, password) => {
    dispatch(loginPost(username, password))
    //setShowLP(false)
    //setShowSMLIO(false)
    //setLoggedIn(true)
  }

  const handleGoogleLogin = () => {
    window.location.href = window.clientConfig.url+"/google_auth";
  }

  const handleSignUp = (username, password) => {
    dispatch(signPost(username, password))
    //setShowLP(false)
    //setShowSMLIO(false)
    //setLoggedIn(true)
  }


  const onDeposit = (amount) => {
    dispatch(depositlink(amount));
    setIsDepositing(true)
  }

  if (!loading && isDepositing&& characters.url!=undefined) {
    
    localStorage.setItem('depositUrlLog',characters)
    window.location.href = characters.url;
    setIsDepositing(false);
  }

  useEffect(() => {
    dispatch(allboxes(1));
    console.log("1")
  }, [dispatch]);
  useEffect(() => {
    dispatch(balanceget());
    console.log("1")
  }, [dispatch])

  useEffect(() => {
    if (characters[0] == "NotLoggedIn") {
      console.log("NotLoggedIn")
      setLoggedIn(false)
    } else {
      if (payloadType == "BALANCE_GET") {
        setLoggedIn(true)
        console.log('balance', characters.balance)
        console.log('bonusBoxes', characters)

        setBonusBoxes(characters.bonus_boxes)
        setBalance(characters.balance)
      }
      else if (payloadType == "LOGIN") {
        if (characters == "ErrorIncorrectPassword") {
          setIsLoginError(true)
        } else {
          setLoggedIn(true)
          console.log("info", characters.balance)
          console.log('balance', characters.balance)
          setBalance(characters.balance)
          setShowLP(false)
        }
      } else if (payloadType == "SIGN") {
        if (characters == "userexists422") {
          setIsSignError(true)
        } else {
          setLoggedIn(true)
          console.log("info", characters.balance)
          console.log('balance', characters.balance)
          setBalance(characters.balance)
          setShowSUP(false)
        }
      }
      else {
        console.log('Unknown Payload')
      }
    }
  }, [loading, characters, payloadType])

  const switchToSUP = () => {
    setShowLP(false)
    setShowSUP(true)
  }
  const switchToLP = () => {
    setShowLP(true)
    setShowSUP(false)
  }


  return (
    <div>
      <StickyMenuTop openBonusBox={()=>{window.location.href='/'}} toggleLP={toggleLP} bonusBoxes={bonusBoxes} balance={balance} toggleDP={toggleDP} toggleWP={toggleWP} isloggedIn={loggedIn} />
      {showLoginPopup ? (<LoginPopup isError={isLoginError} openSignPopup={switchToSUP} handleLoginGoogle={handleGoogleLogin} handleLogin={handleLogin} closePopup={toggleLP} />) : (<></>)}
      {showSignUpPopup ? (<SignUpPopup handleLoginGoogle={handleGoogleLogin} isError={isSignError} openLoginPopup={switchToLP} handleSignUp={handleSignUp} closePopup={toggleSUP} />) : (<></>)}
      {showDepositPopup ? (<DepositPopup onDeposit={onDeposit} togglePopup={toggleDP}></DepositPopup>) : (<></>)}
      {showWithdrawalPopup ? (<WithdrawPopup togglePopup={toggleWP} />) : (<></>)}
      {loggedIn || !showSMLIO ? (<></>) : (<StickyMenuLogInOptions googleLogin={handleGoogleLogin} logIn={toggleLP} signUp={toggleSUP} />)}
      <div className={styles.container}>
        <div className={styles.leftbody}>
          <h1 className={styles.fatheader}>Anboxme: About Us </h1>
          <p className={styles.description}>Welcome to Anboxme - Your Gateway to Exciting Shopping Adventures!</p>
          <p className={styles.description}>At Anboxme, we've revolutionized online shopping with a groundbreaking approach. Imagine shopping for your favorite items, like those stylish sneakers you've had your eye on, but with an exciting twist! Our unique shopping experience combines the thrill of randomness with the promise of savings.</p>
          <p className={styles.description}>How does it work, you ask? It's simple! We offer 'loot boxes' at an incredibly affordable price, giving you the chance to score high-value items at a fraction of the cost. For instance, a loot box may cost just $20, yet it could contain those $450 sneakers you desire. It's like a game of chance, but you never walk away empty-handed.</p>
          <p className={styles.description}>Our commitment to fairness is unwavering. Anboxme employs provably fair technology to ensure that every outcome is transparent and unbiased. We want you to shop with confidence, knowing that every box you open is a genuine opportunity to discover amazing products.</p>
          <p className={styles.description}>Join us in this exciting shopping journey where surprises await at every turn. Say goodbye to mundane shopping and hello to a world of possibilities! At Anboxme, it's not just about shopping; it's about the thrill of the unexpected.</p>
          <p className={styles.description}>Come, explore, and experience shopping like never before with Anboxme.</p>
        </div>
        <LiveAnbox />
      </div>
      <Footer></Footer>
    </div>
  )
};


export default AboutUs;
