import React from "react";
import styles from "./Footer.module.css";
import anboxmelogo from "../../assets/Frame_4644.png";
import { useState } from "react";
import { contactusPost } from "../../redux/actions/contactusAction";

function Footer() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isEmailError,setIEE] = useState(false)

  const isValidEmail=(email) =>{
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }


  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setIEE(!isValidEmail(value))
  };

  return (
    <div className={styles.footerContainer}>
    <div className={styles.footer}>
      <div id={styles.leftfooter}>
        <img className={styles.anboxmelogo} src={anboxmelogo}></img>
        <p className={styles.hint}>
          Open our provably fair mystery boxes and have your unboxed products
          shipped to your doorstep
        </p>
        <div
          className={styles.faDesktop}
          style={{ display: "flex", justifyContent: "stretch" }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <a href="/faq" className={styles.fa}>
              FAQ
            </a>
            <a href="/about" className={styles.fa}>
              About Us
            </a>
            <a href="/privacypolicy" className={styles.fa}>
              Privacy policy
            </a>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <a href="termsandconditions" className={styles.fa}>
              Terms & Conditions
            </a>
            <a href="/provablyfair" className={styles.fa}>
              Provably Fair technology
            </a>
            <a href="cookies" className={styles.fa}>
              Cookie policy
            </a>
          </div>
        </div>
      </div>
      <div id={styles.rightfooter}>
        <h1 className={styles.contact}>Contact Us</h1>
        <form>
          <div className={styles.emailInput}>
            <input
              style={{ width: "100%" }}

              placeholder="Email"
              type="email"
              value={email}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className={styles.textInput}>
            <textarea
              placeholder="Message*"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          {isEmailError ? (<p style={{ color: 'red' }}>Invalid email adress</p>):(<></>)}
        </form>
          <button
            className={styles.submitbtn}
          onClick={!isEmailError ? (() => { contactusPost(email, message); setEmail(''); setMessage('')}):(()=>{})}
          >
            Send
          </button>

        <div className={styles.faMobileBox}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <a href="/faq" className={styles.fa}>
              FAQ
            </a>
            <a href="/about" className={styles.fa}>
              About Us
            </a>
            <a href="/privacypolicy" className={styles.fa}>
              Privacy policy
            </a>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <a href="termsandconditions" className={styles.fa}>
              Terms & Conditions
            </a>
            <a href="/provablyfair" className={styles.fa}>
              Provably Fair technology
            </a>
            <a href="cookies" className={styles.fa}>
              Cookie policy
            </a>
          </div>
        </div>
      </div>
      </div>
      <p id={styles.textAtTheBottomFooter}>Anboxme is a brand name of Zbest Consultancy kft, Reg No: 13-09-234587, Having it's registered address Hungary, 2132 Göd, Kádár utca 7/a</p>
      </div>
  );
}

export default Footer;
